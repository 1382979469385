<template>
  <div id="action_bar">
    <div class="action_container_root">
      <Transition>
        <div
          v-if="show"
          class="action_container_root__item primary_line_container"
        >
          <div class="primary_line_container__item icon">
            <Transition name="icon">
              <div
                v-if="status === 'WAITING'"
                class="spring-spinner icon_waiting"
              >
                <div class="spring-spinner-part top">
                  <div class="spring-spinner-rotator"></div>
                </div>
                <div class="spring-spinner-part bottom">
                  <div class="spring-spinner-rotator"></div>
                </div>
              </div>
              <div v-else-if="status === 'OK'" class="icon_wrapper">
                <svg
                  fill="#5bc367"
                  width="20px"
                  height="15px"
                  viewBox="0 0 400 400"
                >
                  <path
                    d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77    C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769    C373.768,295.816,295.812,373.77,199.996,373.77z"
                  />
                  <path
                    d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003    c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842    c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546    C285.828,129.402,277.523,129.402,272.406,134.526z"
                  />
                </svg>
              </div>
              <div v-else-if="status === 'ERROR'" class="icon_wrapper">
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 22"
                  stroke="#f44c4b"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="none"
                >
                  <path d="M12 8L12 13" />
                  <line x1="12" y1="16" x2="12" y2="16" />
                  <circle cx="12" cy="12" r="10" />
                </svg>
              </div>
              <div v-else-if="status === 'FORBIDDEN'" class="icon_wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="40.000000pt"
                  height="30.000000pt"
                  fill="red"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M2370 5110 c-774 -62 -1465 -456 -1909 -1090 -714 -1021 -577 -2432 320 -3295 400 -385 864 -612 1424 -696 198 -30 512 -30 710 0 560 84 1024 311 1424 696 897 863 1034 2274 320 3295 -359 512 -884 874 -1484 1021 -247 60 -567 88 -805 69z m492 -525 c902 -137 1603 -849 1729 -1755 7 -52 13 -174 13 -270 0 -96 -6 -218 -13 -270 -96 -689 -533 -1287 -1156 -1581 -184 -86 -403 -152 -605 -180 -122 -17 -418 -17 -540 0 -917 128 -1633 844 -1761 1761 -17 122 -17 418 0 540 96 689 533 1287 1156 1581 188 88 419 156 606 178 52 6 110 13 129 15 67 8 345 -4 442 -19z"
                      fill="red"
                    />
                    <path
                      d="M2495 4051 c-16 -10 -38 -27 -48 -39 -45 -50 -46 -64 -47 -714 l0 -618 175 0 175 0 -2 643 -3 644 -29 37 c-16 21 -44 44 -62 52 -47 20 -123 18 -159 -5z"
                      fill="red"
                    />
                    <path
                      d="M2068 3770 c-20 -11 -47 -34 -60 -52 l-23 -33 -3 -502 -3 -503 171 0 171 0 -3 503 -3 502 -23 33 c-49 68 -149 91 -224 52z"
                      fill="red"
                      />
                    <path
                      d="M2946 3780 c-37 -12 -85 -56 -102 -97 -11 -26 -14 -128 -14 -518 l0 -485 29 0 c82 0 198 -57 284 -138 l37 -36 0 569 c0 532 -2 573 -19 610 -38 84 -125 122 -215 95z"
                      fill="red"
                    />
                    <path
                      d="M1654 3471 c-49 -22 -79 -61 -94 -122 -7 -29 -10 -178 -8 -441 l3 -398 69 60 c74 63 146 97 228 107 l48 6 0 341 c0 334 -1 342 -23 380 -26 47 -94 86 -146 86 -20 0 -54 -9 -77 -19z"
                      fill="red"
                    />
                    <path
                      d="M1803 2586 c-106 -34 -185 -106 -225 -205 -22 -54 -23 -66 -23 -396 0 -380 3 -402 73 -545 52 -106 178 -233 282 -283 134 -65 161 -69 523 -65 487 6 441 -19 963 503 416 415 428 430 428 535 0 143 -131 222 -262 158 -25 -11 -100 -73 -167 -137 -153 -146 -163 -151 -213 -123 -44 24 -173 58 -257 67 -78 8 -187 -9 -252 -41 -105 -50 -188 -170 -200 -288 l-6 -56 -37 0 -37 0 8 58 c16 116 59 201 138 275 95 89 249 137 385 123 60 -7 203 -38 240 -52 17 -7 18 -1 14 107 -3 89 -9 124 -25 162 -32 70 -112 149 -186 183 l-62 29 -530 2 c-419 2 -539 -1 -572 -11z"
                      fill="red"
                    />
                  </g>
                </svg>
              </div>
            </Transition>
          </div>
          <div class="primary_line_container__item status">
            <label>Статус: {{ status }}</label>
          </div>
          <div class="primary_line_container__item action">
            <label
              >Действие: {{ action }}
              {{ status === "FORBIDDEN" ? "  (Недостаточно прав)" : "" }}</label
            >
          </div>
          <div
            v-if="broadDescription"
            class="primary_line_container__item show_broad_button"
          >
            <button
              title="Показать сообщение"
              @click="showBroadDescription = !showBroadDescription"
            >
              <div
                v-if="!showBroadDescription"
                class="filter_button_container__item button_close"
              ></div>
              <div
                v-if="showBroadDescription"
                class="filter_button_container__item button_open"
              ></div>
            </button>
          </div>
          <div
            v-if="broadDescription"
            class="primary_line_container__item close_button"
          >
            <button @click="$emit('closeActionBar')" title="Закрыть">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="12px"
                height="12px"
              >
                <path
                  d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                />
              </svg>
            </button>
          </div>
        </div>
      </Transition>
    </div>
    <div
      v-if="broadDescription && showBroadDescription"
      class="action_container_root__item broad_description"
    >
      Сообщение: {{ broadDescription }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // OK, ERROR, WAITING
    status: {
      type: String,
      default() {
        return "OK";
      },
    },
    action: {
      type: String,
    },
    broadDescription: {
      type: String,
      default() {
        return null;
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      showBroadDescription: false,
    };
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";
#action_bar {
  width: 100%;

  .v-enter-active {
    transition: opacity 0.1s ease;
  }
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from {
    transition: opacity 0.1s ease;
  }
  .v-leave-to {
    opacity: 0;
  }

  .icon-enter-active {
    transition: opacity 0.4s ease;
  }
  .icon-leave-active {
    transition: opacity 0.3s ease;
  }

  .icon-enter-from,
  .icon-leave-to {
    opacity: 0;
    transition: opacity 0s ease;
  }

  .defineSpinner(15px);

  .action_container_root {
    display: flex;
    min-height: 30px;
    max-height: 100px;

    line-height: 15px;
    button {
      all: unset;
    }
    .primary_line_container {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      width: 100%;

      .primary_line_container__item {
        &.icon {
          // height: 15px;
          width: 15px;
          margin-right: 5px;
          .icon_wrapper {
            display: flex;
          }
        }
        &.status {
          margin-right: 20px;
        }

        // &.action {
        //   //   flex-grow: 2;
        // }

        &.show_broad_button {
          margin-right: 2px;
        }
        &.close_button {
          margin-left: 20%;
        }
      }
    }
  }

  .broad_description {
    border-top: 1px dashed #c9c9c9;
    height: 40px;
    margin-top: 2px;
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    overflow-y: scroll;
    max-height: 70vh;
  }
}
</style>
